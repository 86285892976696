export const FALLBACK_LOCALE = 'en_US'

export const LANGUAGE_OPTIONS = [
  { key: 'fr', name: 'Français' },
  { key: 'en', name: 'English' },
]

export const LOCALE_TO_LANG: Record<string, string> = {
  fr_FR: 'fr',
  en_US: 'en',
}

export const LANG_TO_LOCALE_SNAKE: Record<string, string> = {
  fr: 'fr_FR',
  en: 'en_US',
}

export const LANG_TO_LOCALE_KEBAB: Record<string, string> = {
  fr: 'fr-FR',
  en: 'en-US',
}

export const LOCALE_DATE_FORMAT: Record<string, string> = {
  fr: 'DD/MM/YYYY',
  en: 'MM/DD/YYYY',
}
