import { FALLBACK_LOCALE, LANG_TO_LOCALE_SNAKE, LOCALE_TO_LANG } from '@/constants/language'
import type { I18nLabel, I18nLabelLanguage } from '@/views/Suppliers/Suppliers.interfaces'

export const langToLocale = (lang: string) => {
  const language = lang.substring(0, 2)

  if (language in LANG_TO_LOCALE_SNAKE) {
    return LANG_TO_LOCALE_SNAKE[language]
  }

  return FALLBACK_LOCALE
}

export const getNavigatorLocale = () => langToLocale(window.navigator.language)

export const apiV2Translate = (label: I18nLabel, lang: I18nLabelLanguage = 'EN'): string => {
  if (lang in label) {
    return label[lang]
  }

  const DEFAULT_LANG = 'EN'

  return label[DEFAULT_LANG] ?? ''
}

export const localeToLang = (locale: keyof typeof LOCALE_TO_LANG) => {
  if (locale in LOCALE_TO_LANG) {
    return LOCALE_TO_LANG[locale]
  }

  return LOCALE_TO_LANG[FALLBACK_LOCALE]
}

export const isLanguageFrench = (): boolean => {
  const userLanguage = window.navigator.language
  return userLanguage === 'fr' || userLanguage.startsWith('fr-')
}
